import "bootstrap/dist/css/bootstrap.css";
import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight, faQ } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import insurance from '../images/insurance.jpg';
import hospital from '../images/hospital.jpg';
import policy from '../images/policy.jpg';
import check from '../images/check.png';
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg';
import dload from '../images/dload.png';
import Footer from './footer.js';
import Navigation from './nav';


import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });
  
  


 function Forms() {

  window.scrollTo(0, 0)

 const [redText, setRedText] = useState("")
 const [blackText, setBlackText] = useState("")
 const [hero, setHero] = useState("")
 const[faqs, setFAQ] = useState([]) 
 const[forms, setForms] = useState([])
 const[guides, setGuides] = useState([])
	
	
  
	useEffect( () => {
	  client
	.query({
	  query: gql`
	   query{
      pages {
        edges {
          node {
            claimForms {
              blackheadingtext
              forms {
                downloadlink
                formimage {
                  mediaItemUrl
                }
                formname
              }
              formsfaqs {
                question
                solution
              }
              heroimage {
                mediaItemUrl
              }
              redheadingtext
              userguides {
                downloadlink
      
                guidename
              }
            }
          }
        }
      }
	  }
	  `,
	})
	.then(async result => {
	  let formsHolder=[]
    let faqHolder = []
    let guidesHolder = []
	   console.log(result)
	  console.log(result.data.pages.edges[2].node.claimForms.formsfaqs)  

      setRedText(result.data.pages.edges[2].node.claimForms.redheadingtext)
		  setBlackText(result.data.pages.edges[2].node.claimForms.blackheadingtext)
		  setHero(result.data.pages.edges[2].node.claimForms.heroimage.mediaItemUrl)
	   
		   for(let x=0; x < result.data.pages.edges[2].node.claimForms.formsfaqs.length; x++ ){
				
			  let faqDetails = [{question:result.data.pages.edges[2].node.claimForms.formsfaqs[x].question, solution:result.data.pages.edges[2].node.claimForms.formsfaqs[x].solution}]
  
			  faqHolder.push(faqDetails)
		   }

       for(let x=0; x < result.data.pages.edges[2].node.claimForms.forms.length; x++ ){
				
			  let formDetails = [{formname:result.data.pages.edges[2].node.claimForms.forms[x].formname, image:result.data.pages.edges[2].node.claimForms.forms[x].formimage.mediaItemUrl, link:result.data.pages.edges[2].node.claimForms.forms[x].downloadlink }]
  
			  formsHolder.push(formDetails)
		   }

       for(let x=0; x < result.data.pages.edges[2].node.claimForms.userguides.length; x++ ){
				
			  let guidesDetails = [{name:result.data.pages.edges[2].node.claimForms.userguides[x].guidename, link:result.data.pages.edges[2].node.claimForms.userguides[x].downloadlink}]
  
			  guidesHolder.push(guidesDetails)
		   }


		   console.log(faqHolder)
		   setFAQ(faqHolder)
       setForms(formsHolder)
       setGuides(guidesHolder)

		   console.log(faqs)
	
	});
	
	}, [])   
	
	  
	let faqDetailsPack
	if(faqs.length > 0){ 
  
	  faqDetailsPack = faqs.map((faq, i) => 
      <div className="accordion-item accordion-comp">
    <h2 className="accordion-header accordion-comp-header">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseForm${i}`} aria-expanded="true" aria-controls="collapseForm">
    <h5 className="accordion-comp-title"> 
    {faq[0].question}
    </h5> 
      </button>
    </h2>
    <div id={`#collapseForm${i}`}className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
         {faq[0].solution}
        </div>
    </div>
  </div>
	
	)


  }


  let formsDetailsPack
	if(forms.length > 0){ 
  
	  formsDetailsPack = forms.map((form, i) => 
<div className="card card-static col-12 col-md-4 inlineMargin" style={{width: "20rem"}}>
  <div className="card-body card-static__content">
    <Link to={form[0].link} className="link">
       <div className="row"> 
          <div className="col-4 col-md-4 text-end"> 
            <img src={form[0].image} width="100%" />
          </div>
      <div className="col-8 paddingOff"> 
           <p className="card-text">  {form[0].formname}   <img src={arrow} /> </p>
      </div>    
    </div>
    </Link>
       </div>
      </div>
	
	)


  }


  let guidesDetailsPack
	if(guides.length > 0){ 
  
	  guidesDetailsPack = guides.map((guide, i) => 
    <div className="card card-static col-12 col-md-4 noBackground inlineMargin" style={{width: "20rem"}}>
     
    <div className="card-body card-static__content">
    <Link to={guide[0].link} className="link"> 
    <div className="row text-center "> 
     
     <div className="col-12 text-center circlePadding clearSpaceMini"> 
   <img src={dload} width="60%" /> 
     </div>
     <p>  {guide[0].name} </p>
   </div>
   <div className="card-static__content--btn btn-arrow1"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
   </Link>

      </div>



   
 
    




     </div>
	
	)


  }

  return(
       
<div className="">

<header> 
   <Navigation />
      </header>

<section data-pru-link="" className="pd-100-60 section page-title">
					  	<div className="page-title__page-wrapper row">
                          <div className="col-md-5">
							<div className="page-title__content-outer">
											<div className="container">
											  <div className="row">
											
													<div className="page-title__content">
														<h1 className="page-title__content--name"> {blackText} <span className="color-red"> {redText} </span>
														</h1>
														<div className="page-title__content--desc"> 

                                                        <p className="editorial-body-copy">
                                                        We are always ready to support you in your time of need. Review our form gallery and download the form applicable to
                                                        your claim.  </p>
                           
                                                        <p className="editorial-body-copy">
                                                       Claims are usually processed within 14days of receiveing your complete application
                                                        </p>

                                                        <p className="editorial-body-copy">
                                                    Use the Frequently asked questions below to find out more information on claims
                                                        </p>
 

                                                        </div>
														</div>
											
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div className="offset-md-1 col-md-6">
										  <div className="page-title__img-outer">
											<div className="row">
										
											      <div className="page-title__img-inner">
													 <picture>
													   <img className="page-title--img lazyloaded" src={hero} width="100%" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>




<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="col-12 col-md-6 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong>  Frequently Asked Questions  </strong> </h1>

</div>
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray"></div>


<div className="col-12 inlineDisplay"> 

<div className="accordion" id="accordionExample">
{faqDetailsPack}

</div>

</div>
</div>
</div>
</section>


<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray1"></div>
<div className="col-12 offset-md-7 col-md-5 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong> Download </strong> <span className="lightFont"> the forms you need </span> </h1>
<p> Secure your families happiness today with ease </p>

</div>

<div className="row inlineDisplay"> 

{formsDetailsPack}

</div>
</div>
</div>
</section>


<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 

<div className="component-rich-text__wrapper">
<div className="bk-blue-gray4"></div>

<div className="row"> 
<div className="col-12 col-md-5"> 
<h1 className="basic-panel__content--title"> <strong>  How to   </strong> <span className="lightFont"> fill you claim forms </span> </h1>
</div>
<div className="col-12 col-md-7  inlineDisplay"> 
{guidesDetailsPack}

</div>

</div>
</div>
</div>
</section>


<footer>
         <Footer />
        </footer>
</div>

  )

 }

 export default Forms;