import "bootstrap/dist/css/bootstrap.css";
import $ from 'jquery';
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, Outlet, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import hospital from '../images/hospital.jpg';
import family from '../images/family.jpg';
import extend from '../images/extend.jpg';
import build from '../images/build.jpg';
import man from '../images/man.jpg'
import policy from '../images/policy.jpg';
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Lana from '../images/lana.jpg';
import arrow from '../images/arrow.svg';
import Footer from './footer.js';
import Navigation from './nav';


import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });
    

 function PolicySingle() {

  $(".quote").click(function() {
    $('html,body').animate({
        scrollTop: $(".quotation").offset().top},
        'fast');
  });


  $(".calculate").click(function() {
    $('html,body').animate({
        scrollTop: $(".result").offset().top},
        'fast');
  });
  

  //window.scrollTo(0, 0)
  const params = useParams()

 const [redText, setRedText] = useState("")
 const [blackText, setBlackText] = useState("")
 const [description, setDescription] = useState("")
 const [hero, setHero] = useState("") 
 const [benefits, setBenefits] = useState("")
 const [benefitsSubheading, setBenefitsSubheading] = useState("")
 const [faqs, setFaqs] = useState("")
 const [marketing, setMarketing] = useState("")
 const [products, setProducts] = useState("")
 const [benefitsLightText, setBenefitsLightText] = useState("")
 const [benefitsBoldText, setBenefitsBoldText] = useState("")
 const [formErrors, setFormErrors] = useState({});
 const [plansInfo, setPlansInfo] = useState([]);
 const [frequencyOptions, setFrequencyOptions] = useState([]);
 const [tenureOptions, setTenureOptions] = useState([]);
 const [showPremiumField, setShowPremiumField] = useState(false);
 const [showSumAssuredField, setShowSumAssuredField] = useState(false);
 const [responseMessage, setResponseMessage] = useState('');
 const [showAlert, setShowAlert] = useState(false);
 const [planId , setPlanId] = useState("AEE")  
 const [planIdOptions, setPlanIdOptions] = useState([])

  let benefitsArray =[]
  let faqsArray =[]
  let marketingArray =[]
  let productsArray = []
  let planidArray = []


  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    paymentGap: '',
    description: '',
    isPremium: '',
    tenure: '',
    dateOfBirth: '',
    premium: '',
    sumAssured: ''
  });



  const parseFrequency = (frequencyString) => {
    const frequencyMap = {
      "01": "Monthly",
      "03": "Quarterly",
      "06": "Semi-Annual",
      "12": "Annual",
      "OT": "One Time"
    };
  
    const frequencies = [];
  
    
    if (frequencyString.includes(",")) {
      frequencies.push(...frequencyString.split(","));
    } else {
      const regex = /(\d{2}|OT)/g;
      const matches = frequencyString.match(regex);
      if (matches) {
        frequencies.push(...matches);
      }
    }
  
    return frequencies.map(freq => ({
      id: freq,
      value: frequencyMap[freq] || freq
    }));
  };  




  let  updatePlanId = (e) => {
     e.preventDefault()
    setPlanId(e.target.value)

  }
      



  useEffect( () => {





     client
     .query({
       query: gql`
       query($id:ID!){
        policyPagesBy(id:$id) {
          id
          policy {
            blackheadingtext
            redheadingtext
            productdescription
            productbenefits {
              lightheadingtext
              boldheadingtext
              benefitssubheading
              benefitscard {
                benefitsheading
                benefitsexplanation
                benefitsimage {
                  mediaItemUrl
                }
              }
            }
            productfaq {
              question
              solution
            }
            productimage {
              mediaItemUrl
            }
            productmarketing {
              descriptiona
              descriptionb
              boldtextheading
              lighttextheading
              heading
            }
            planid{
              id
              name
            }
          }
        }
     }
     `,
     variables: {id:params.id}
     })
     .then(async result => { 
      console.log(result)
  
        setBlackText(result.data.policyPagesBy.policy.blackheadingtext)
        setRedText(result.data.policyPagesBy.policy.redheadingtext)
        setDescription(result.data.policyPagesBy.policy.productdescription)
        setHero(result.data.policyPagesBy.policy.productimage.mediaItemUrl)
        setBenefitsSubheading(result.data.policyPagesBy.policy.productbenefits[0].benefitssubheading)
        setBenefitsLightText(result.data.policyPagesBy.policy.productbenefits[0].lightheadingtext)
        setBenefitsBoldText(result.data.policyPagesBy.policy.productbenefits[0].boldheadingtext)

      
       let benefitsDetailPack 
       let faqDetailPack
       let marketingDetailPack
       let idDetailPack
       

       let x 

      // if (newsArray.length != result.data.news.edges[0].node.categories.edges[0].node.news.edges.length){

      if (benefitsArray.length != result.data.policyPagesBy.policy.productbenefits[0].benefitscard.length){
        for(x = 0; x < result.data.policyPagesBy.policy.productbenefits[0].benefitscard.length; x++){ 
            //console.log(x)
           //alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)        
           benefitsDetailPack = [{heading:result.data.policyPagesBy.policy.productbenefits[0].benefitscard[x].benefitsheading,body:result.data.policyPagesBy.policy.productbenefits[0].benefitscard[x].benefitsexplanation, image:result.data.policyPagesBy.policy.productbenefits[0].benefitscard[x].benefitsimage.mediaItemUrl }]
           benefitsArray.push(benefitsDetailPack)      
    }

  }
    
  if (faqsArray.length != result.data.policyPagesBy.policy.productfaq.length){
    for(x = 0; x < result.data.policyPagesBy.policy.productfaq.length; x++){ 
      //console.log(x)
//   alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
         
      faqDetailPack = [{question:result.data.policyPagesBy.policy.productfaq[x].question, solution:result.data.policyPagesBy.policy.productfaq[x].solution }]
      faqsArray.push(faqDetailPack)
       
   }

  }


  if (marketingArray.length != result.data.policyPagesBy.policy.productmarketing.length){
   for(x = 0; x < result.data.policyPagesBy.policy.productmarketing.length; x++){ 
  //console.log(x)
//   alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
     
  marketingDetailPack = [{heading:result.data.policyPagesBy.policy.productmarketing[x].heading, descriptiona:result.data.policyPagesBy.policy.productmarketing[x].descriptiona, descriptionb:result.data.policyPagesBy.policy.productmarketing[x].descriptionb, boldSubheading: result.data.policyPagesBy.policy.productmarketing[x].boldtextheading, lightSubheading:result.data.policyPagesBy.policy.productmarketing[x].lighttextheading }]
  marketingArray.push(marketingDetailPack)
   
  }
  }

  if (planidArray.length != result.data.policyPagesBy.policy.planid.length){
    for(x = 0; x < result.data.policyPagesBy.policy.planid.length; x++){ 
   //console.log(x)
 //   alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
      
   idDetailPack = [{id:result.data.policyPagesBy.policy.planid[x].id, name:result.data.policyPagesBy.policy.planid[x].name }]
   planidArray.push(idDetailPack)

   
    
   }
   }
    setBenefits(benefitsArray)
    console.log(faqsArray)
    setFaqs(faqsArray)
    setMarketing(marketingArray)
    setPlanIdOptions(planidArray)
    
    let plan = planidArray[0][0].id
    setPlanId(plan)

    console.log(planidArray[0][0].id)

   // }



   const fetchPlanInfo = async () => {
    try {
      
      const response = await fetch(`https://jcms.prudentialzenith.com/jcmsjobcard/api/policy/plainid?planID=${planId}`);
      const result = await response.json();

      if (result.success) {
        const planData = result.data;

        console.log('planData:', planData);

        const frequencyArray = parseFrequency(planData.paymentGap);

        console.log('frequencyArray:', frequencyArray);

        const minTenor2 = planData.minTenor2 || 0;
        const tenureArray = Array.from({ length: planData.tenor + 1 }, (_, i) => minTenor2 + i);
        console.log('tenureArray:', tenureArray);

        setFrequencyOptions(frequencyArray);
        setTenureOptions(tenureArray);

        setFormData(prevState => ({
          ...prevState,
          description: planData.description,
        }));

        console.log('sumAssuredSpName:', planData.sumAssuredSpName === '');

        if (planData.premium2PaySpName === '') {
          setShowPremiumField(true);
        } else if (planData.sumAssuredSpName === '') {
          setShowSumAssuredField(true);
        }

        
      } else {
        console.error('Error in response:');
      }
      
    } catch (error) {
      console.error('Error fetching plans');
    }
  };

  fetchPlanInfo();
      
     })






    }, [])   


    const handleInputChange = (e) => {
    
      const { name, value } = e.target;
      alert(e.target.value)
      setFormData({
        ...formData,
        [name]: value
      });


    };


    const validateForm = () => {
      let errors = {};
  
      if (!formData.firstName) errors.firstName = 'First Name is required';
      if (!formData.lastName) errors.lastName = 'Last Name is required';
      if (!formData.age) errors.age = 'Age is required';
      
  
      setFormErrors(errors);
      return Object.keys(errors).length === 0;
    };


    let { register, handleSubmit, watch, formState: { errors } } = useForm()

    let onSubmit = async function (data){

      const apiBaseUrl = "https://jcms.prudentialzenith.com/imultiplan/api/v1/annuity";
      const formattedDob = new Date(data.dateOfBirth).toLocaleDateString('en-GB'); 
      const endpoint = showPremiumField ? "sumassured" : "regular-premium";
      const apiUrl = `${apiBaseUrl}/${endpoint}`;

      const payload = showPremiumField ? {
        premiumFrequency: (data.frequency),
        planDuration: parseInt(data.tenure),
        planId: planId,
        birthDate: formattedDob,
        regularPremium: parseInt(data.premium),
      } : {
        premiumFrequency: (data.frequency),
        planDuration: parseInt(data.tenure),
        planId: planId,
        birthDate: formattedDob,
        sumAssured: parseInt(data.sumAssured),
      };

      console.log('Submitting form:', payload);

      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        });

        const result = await response.json();
        console.log('result:', result);
        if (result.statusCode === 200) {
          console.log('Calculation successful:', result);
          const message = showPremiumField
            ? `Hello ${data.firstName} ${data.lastName}, your Sum Assured is ${result.amount}.`
            : `Hello ${data.firstName} ${data.lastName}, your Premium is ${result.amount}.`;
          setResponseMessage(message);
          setShowAlert(true);
          // alert(responseMessage);
        } else {
          console.error('Error in response:', result.message);
        }
      } catch (error) {
        console.error('Error submitting the form:', error);
      }

    
    return false
       
  
  
  };


    let marketingDetails

  if(marketing.length > 0){ 
  
    marketingDetails = marketing.map((market, i) => 
      <section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
    <div className="container">
        

        <div className="component-rich-text__wrapper">
<div className="bk-blue-gray-intro"></div>
<div className="container-rich-text">
<div className="row">
<div className="rich-text">
<h2 className="bannerText"> {market[0].heading} </h2>
<p className="editorial-body-copy">{market[0].descriptiona} </p>
<p className="editorial-body-copy">{market[0].descriptionb}</p>
</div>
</div>
</div>
</div>


    </div>
</section>
    
    )

  }


  let faqDetails

  if(faqs.length > 0){ 
  
    faqDetails = faqs.map((faq, i) => 
      <div className="accordion-item accordion-comp">
    <h2 className="accordion-header accordion-comp-header">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsePoli${i}`} aria-expanded="true" aria-controls="collapseFaq">
    <h5 className="accordion-comp-title"> {faq[0].question}  
    </h5> 
    
      </button>
    </h2>
    <div id={`collapsePoli${i}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      {faq[0].solution}
      </div>
    </div>
  </div>
    
    )

  }


  let benefitsDetails

  if(benefits.length > 0){ 
  
    benefitsDetails = benefits.map((benefit, i) => 
<div className="card card-static inlineMargin paddingOff" style={{width: "20rem"}}>
  <img src={benefit[0].image} width="100%" className="border-radius-card" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> {benefit[0].heading} </h2>
    <p className="card-text"> {benefit[0].body} </p>
       </div>

      </div>
    
    )

  }


  return(
       
<div className="">
<header> 
   <Navigation section={params.section} />
      </header>
<section data-pru-link="" className="pd-100-60 section page-title">
					  	<div className="page-title__page-wrapper row fullWidth">
                          <div className="col-md-5">
							<div className="page-title__content-outer">
											<div className="container">
											  <div className="row">
											
													<div className="page-title__content">
														<h1 className="page-title__content--name">  {blackText}
														</h1>
														<div className="page-title__content--desc clearSpaceBottom1"> 
                                                        {description}
                                                        </div>

                                                        <button className="cta-primary hero-banner__content--btn quote">
            
                                                 <span> Get A Quote</span>
       
                                                        </button>

														</div>
											
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div className="offset-md-1 col-md-6">
										  <div className="page-title__img-outer">
											<div className="row">
										
											      <div className="page-title__img-inner paddingOff">
													 <picture>
													   <img className="page-title--img lazyloaded" src={hero} width="100%" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>


{marketingDetails}




        <section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray"></div>

<div className="col-12 col-md-6"> 
<h1 className="basic-panel__content--title"> {benefitsBoldText} <span className="lightFont"> {benefitsLightText} </span> </h1>
<p> {benefitsSubheading} </p>
</div>

<div className="row inlineDisplay"> 


{benefitsDetails}
</div>
</div>
</div>
</section>




<section className="component-rich-text gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">

<div className="row inlineDisplay quotation"> 

<section className="section pd-100-60 basic-panel promotion" data-pru-link="">
<div className="row ">

<div className="col-12 col-md-5"> 
                <div className="basic-panel__wrapper">
                <div className="bk-blue-gray5"></div>
                    <div className="container result">

                    <div className="card card-static inlineMargin" style={{width: "20rem"}}>
  <img src={man} width="100%" className="border-radius-card" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> Fill the Form to get started</h2>
    <p className="card-text"> {responseMessage} </p>
    </div>

      </div>
                        
                            </div>
                            </div>
                            </div>
                            <div className="col-12 col-md-7"> 
                            <div className="basic-panel__content-outer textGroupPosition">
                                <div className="container">
                                    <div className="row">
                                    <div className="col-12 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong> Calculate your premium </strong> <br/> <span className="lightFont"> Use the form below </span> </h1>
</div>

                                    <div class="section-input-form">
                                  <h2 class="group-field__title">Your Scenario details</h2>
                                 



<form onSubmit={handleSubmit(onSubmit)}>
                              <div className="input-text-field">

                              <div className="input-text-field">
                             
                                <div className="field-item">
                                  <select
                                    className="field-input"
                                    name="plan"
                                    required
                                    onChange={updatePlanId}
                                 
                                  >
                                     {planIdOptions.map(option => (
                                      <option key={option} value={option[0].id} selected>{option[0].name} </option>
                                    ))}
                                  
                                  </select>
                                  <label className="field-placeholder" For="tenure">Select Policy Variant</label>
                              
                                </div>
                              </div>

                              {errors.firstName?.type === 'required' && <p className="redText" role="alert"> Firstname is required</p>} 
                                <div className="field-item">
                                <input class="field-input" placeholder=" " type="text" name="firstName" id="first-name" required="" {...register("firstName", {required:true})} aria-invalid={errors.firstName ? "true" : "false"} />
                               <label class="field-placeholder" for="first-name">First Name</label>
                                       
                                
                                </div>
                              </div>

                              <div className="input-text-field">
                              {errors.lastName?.type === 'required' && <p className="redText" role="alert"> Lastname is required</p>} 
                                <div className="field-item">
                                <input class="field-input" placeholder=" " type="text" name="lastName" id="last-name" required="" {...register("lastName", {required:true})} aria-invalid={errors.lastName ? "true" : "false"} />
                               <label class="field-placeholder" for="first-name">Last Name</label>
                                       
                                  
                                </div>
                              </div>

                        
                              <div className="input-text-field">
                              {errors.dateOfBirth?.type === 'required' && <p className="redText" role="alert"> Date of birth is required</p>} 
                                <div className="field-item">
                                <input class="field-input" placeholder=" " type="date" name="dateOfBirth" id="dob" required="" {...register("dateOfBirth", {required:true})} aria-invalid={errors.dateOfBirth ? "true" : "false"} />
                               <label class="field-placeholder" for="first-name">Date of Birth </label>
                                       
                                  
                                </div>
                              </div>

                              <div className="input-text-field">
                              {errors.frequency?.type === 'required' && <p className="redText" role="alert"> Frequency is required</p>} 
                                <div className="field-item">
                                  <select
                                    className="field-input"
                                    name="frequency"
                                    required=""
                                    {...register("frequency", {required:true})} aria-invalid={errors.frequency ? "true" : "false"}

                                  >
                                    <option value="" disabled>Select Frequency</option>
                                    {frequencyOptions.map(option => (
                                      <option key={option.id} value={option.id}>{option.value}</option>
                                    ))}
                                  </select>
                                  <label className="field-placeholder" for="frequency">Payment Frequency</label>
                                
                                </div>
                              </div>

                              <div className="input-text-field">
                              {errors.tenure?.type === 'required' && <p className="redText" role="alert"> Tenure is required</p>} 
                                <div className="field-item">
                                  <select
                                    className="field-input"
                                    name="tenure"
                                    required=""
                                    {...register("tenure", {required:true})} aria-invalid={errors.tenure ? "true" : "false"}
                                  >
                                    <option value="" disabled>Select Tenure</option>
                                    {tenureOptions.map(option => (
                                      <option key={option} value={option}>{option} years</option>
                                    ))}
                                  </select>
                                  <label className="field-placeholder" For="tenure">Tenure</label>
                              
                                </div>
                              </div>

                              {showPremiumField && (
                                <div className="input-text-field">
                                           {errors.premium?.type === 'required' && <p className="redText" role="alert"> Premium is required</p>} 
                                  <div className="field-item">
                                    <input className="field-input" placeholder=" " type="number" name="premium"  required=""  {...register("premium", {required:true})} aria-invalid={errors.premium ? "true" : "false"} />
                                    <label className="field-placeholder" htmlFor="premium">Premium</label>
                                 
                                  </div>
                                </div>
                              )}

                              {showSumAssuredField && (
                                <div className="input-text-field">
                                           {errors.sumAssured?.type === 'required' && <p className="redText" role="alert"> Sum Assured is required</p>} 
                                  <div className="field-item">
                                    <input className="field-input" placeholder=" " type="number" name="sumAssured" required='' {...register("sumAssured", {required:true})} aria-invalid={errors.sumAssured ? "true" : "false"} />
                                    <label className="field-placeholder" For="sumAssured">Sum Assured</label>
                                 
                                  </div>
                                </div>
                              )}

                              <button type="submit" className="cta-primary hero-banner__content--btn ">
                                <span className="calculate">Calculate Premium</span>
                              </button>
                            </form>

                                  
                               
                                 

                                    </div>

                                    

   
                                    </div>
                                </div>
                            </div>
                            </div>
                
           
                </div>
            </section>








</div>
</div>
</div>
</section>


{/*

<div data-pru-link="" class="container">
                    <div class="contact-form row pd-100-60">
                       <div class="col-md-7 col-lg-6 contact-form__input">
                         <form id="form" data-submit-form="" data-url-submit="/system/modules/com.prudential.v2.pamb/action/lead-generation-form.jsp" data-analytics="{&quot;event&quot;:&quot;formStarts&quot;,&quot;formName&quot;:&quot;Lead Generation Form mm&quot;,&quot;formType&quot;:&quot;Lead Generation mm&quot;}" data-url-redirect="/en/thank-you/" data-method="POST" novalidate="">
                            <div class="title-form__wrapper">
                                <h1 class="title-form" data-product-title=""><span class="title-form__bold">Get In Touch With Us</span> </h1>
                                </div>
                             <div class="notification-form d-none" data-notification=""><span class="icon icon-warning-triangle notification-form__icon"></span>
                                <div class="notification-form__content">
                                  <div class="notification-form__title"> *Please fill in all fields</div>
                                  <div class="notification-form__desc" data-notification-message=""> *Please fill in all fields</div>
                                </div>
                             </div>

                             <p class="mandatory-text">All fields marked with * are mandatory</p>
        
                             <h2 class="group-field__title" data-required="">Are you a Prudential customer?</h2>
                             <fieldset class="section-radio-checkbox" data-radio-checkbox-popup="">
                                <legend class="sr-only">Are you a Prudential customer?</legend>
                                <div class="radio-checkbox__wrapper field-item">
                                    <div class="radio-checkbox">
                                            <label class="radio-checkbox__label" for="yes">
                                                <input class="radio-checkbox__input" type="radio" name="policyRadios" id="yes" required="" data-parsley-required-message="This is required" value="Yes" data-parsley-multiple="policyRadios" /><span></span>Yes</label>
                                        </div>
                                    <div class="radio-checkbox">
                                            <label class="radio-checkbox__label" for="no">
                                            <input class="radio-checkbox__input" type="radio" name="policyRadios" id="no" value="No" data-parsley-multiple="policyRadios" />
                                                <span></span>
                                                No
                                                </label>
                                        </div>     
                                    </div>
                             
                                </fieldset>

                                <div class="section-input-form">
                                  <h2 class="group-field__title">Your details</h2>
                                 
                                    <div class="input-text-field">
                                          <div class="field-item">
                                              <input class="field-input" placeholder=" " type="text" name="first-name" id="first-name" required="" data-parsley-required-message="Please fill in your first name" />
                                              <label class="field-placeholder" for="first-name">First Name</label>
                                          </div>
                                        </div>
                               
                                    <div class="input-text-field">
                                          <div class="field-item">
                                               <input class="field-input" placeholder=" " type="text" name="last-name" id="last-name" required="" data-parsley-required-message="Please fill in your surname" />
                                               <label class="field-placeholder" for="last-name">Surname</label>
                                          </div>
                                        </div>
                                    </div>


                                    <div class="section-input-form">
                                  <h2 class="group-field__title">Preferred Mode of Contact</h2>
                                 
                                    <div class="input-text-field">
                                          <div class="field-item">
                                              <input class="field-input" placeholder=" " type="number" name="cell-no" id="cell-no" required="" data-parsley-required-message="Please fill in your cell no" />
                                              <label class="field-placeholder" for="first-name">Phone number</label>
                                          </div>
                                        </div>
                               
                                    <div class="input-text-field">
                                          <div class="field-item">
                                               <input class="field-input" placeholder=" " type="email" name="email" id="email" required="" data-parsley-required-message="Please fill email address" />
                                               <label class="field-placeholder" for="last-name">Email</label>
                                          </div>
                                        </div>
                                    </div>

                                    <a className="cta-primary hero-banner__content--btn" target="_blank" href="" title="Discover More">
            
                                      <span>  Submit  </span>
       
                                         </a>
                              

                             </form>
                             </div>
                             </div>
                             </div>

 */}



   {/*                
<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="col-12 col-md-5 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong>  Learn More  </strong> </h1>

</div>
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray"></div>


<div className="col-12 inlineDisplay"> 

<div className="accordion" id="accordionExample">

{faqDetails}

</div>

</div>
</div>
</div>
</section>          


                            */}


<footer>
         <Footer />
        </footer>
</div>

  )

 }

 export default PolicySingle;